import alert from "./alert";
import common from "./common";
import dashboard from "./dashboard";
import datatable from "./datatable";
import footer from "./footer";
import inquiry from "./inquiry";
import login from "./login";
import search from "./search";
import mypage from "./mypage";

const enUS = {
  alert,
  common,
  dashboard,
  datatable,
  footer,
  inquiry,
  login,
  search,
  mypage,
};

export default enUS;
