import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import { useMainStore } from "@/store/main";
import enUS from "./locales/en-US/index";
import koKR from "./locales/ko-KR/index";
import jaJP from "./locales/ja-JP";
import VueApexCharts from "vue3-apexcharts";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.use(createPinia());
const mainStore = useMainStore();
type MessageSchema = typeof koKR;
const i18n = createI18n<[MessageSchema], "en-US" | "ko-KR" | "ja-JP">({
  legacy: false,
  locale: mainStore.language,
  messages: {
    "en-US": enUS,
    "ko-KR": koKR,
    "ja-JP": jaJP,
  },
});

app.use(router);
app.use(i18n);
app.use(VueApexCharts);
app.mount("#app");
