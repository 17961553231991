const dashboard = {
  discover: {
    askurl_title: "Phishing、Smishing、Qshing検知および分析サービス",
    askurl_expl:
      "AskURLは、Web Mail、SMS、Messengerなどを介して流入するPhishing、Smishing、Qshingなどのサイバー脅威を、類似度分析技術と生成型AI技術を活用してリアルタイムの検知と分析を行います。",
  },
  search: {
    domain_placeholder: "Domain、URLを入力してください。",
    sms_placeholder: "SMSメッセージを入力してください。",
    qr_placeholder: "QRイメージを登録してください。",
    resource_placeholder: "Resource Hash値を入力してくだだい。",
  },
  worldmap: {
    detection_by_country: "国別{cls}の検知順位",
    detection_by_type: "タイプ別検知率",
  },
  latest: {
    latest: "最近の分析状況",
    date: "日付",
    status: "状態",
    resource: "リソース数",
    link: "リンク数",
    tags: "タギング",
    country: "国",
  },
  blog: {
    title: "Nurilabセキュリティブログ",
  },
  boannews: {
    title: "セキュリティニュース",
  },
  news: {
    title: "AskURLアップデートニュース",
  },
};

export default dashboard;
