const search = {
  tab: {
    sms: "SMS 문자",
    qr: "QR코드(이미지)",
  },
  summary: {
    analysis: "분석결과",
    scan_at: "최근 스캔 시간",
    status: "상태",
    tags: "태그",
    issuer: "발급 기관",
    subject: "발급 대상",
    not_before: "발급 날짜",
    not_after: "만료일",
    version: "버전",
    judgment_basis: "최종 판단 근거(By ‘Generative AI’)",
    analysis_by_engine: "엔진별 분석 결과 및 판단 근거",
    screenshot: "스크린샷",
    doamin_title: "도메인",
    domain: "도메인 이름",
    registered_date: "등록일",
    registrant: "등록자",
    registrant_address: "등록자 주소",
    updated_date: "최근 갱신일",
    expiration_date: "만료일",
    name_servers: "네임서버",
    ip_address: "IP 주소",
    nation: "국가",
    host_name: "호스트 이름",
    screenshot_none: "스크린샷 이미지가 없습니다.",
    host_ip_none: "호스트IP 정보가 없습니다.",
    domain_none: "도메인 정보가 없습니다.",
    certificate_none: "인증서 정보가 없습니다.",
    hash_none: "Hash 정보가 없습니다.",
  },
  resources: {
    tips: "해당 해시 파일이 포함되어 있는 Domain(URL) 정보를 보여줍니다.",
  },
  scanning: {
    url_scanning: "URL을 스캔하고 있습니다.",
    wait_a_moment: "잠시만 기다려 주세요.",
  },
};

export default search;
