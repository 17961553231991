const scanningRoutes = [
  {
    path: "scanning/:id",
    name: "scanning",
    component: () =>
      import(
        /* webpackChunkName: "scanning" */ "@/views/scanning/ScanningView.vue"
      ),
  },
];

export default scanningRoutes;
