const footer = {
  term_of_use: "利用規約",
  privacy_policy: "個人情報処理方針",
  business_number: "事業者番号",
  address:
    "(07228) ソウル市永登浦区永新路220、1305号、1306号(永登浦洞8街、KnKデジタルタワー)(株)Nurilab",
  ceo: "代表取締役",
  ceo_name: "：チェ・ウォンヒョク、パク・ジョンホ",
  main_phone: "代表電話",
  technical_support: "技術サポート",
  fax: "ファックス",
  email: "メール",
};

export default footer;
