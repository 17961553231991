const newsRoutes = [
  {
    path: "/news",
    name: "update_news",
    component: () =>
      import(
        /* webpackChunkName: "update_news" */ "@/views/update/UpdateView.vue"
      ),
  },
];

export default newsRoutes;
