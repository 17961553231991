const mypage = {
  profile: {
    profile: "프로필 설정",
    user_info: "사용자 정보",
    email: "이메일",
    nickname: "닉네임",
    nickname_add: "닉네임을 설정하세요.",
    password: "비밀번호 ",
    nickname_edit_confrim_title: "닉네임 변경 확인",
    nickname_edit_confrim_content: "닉네임을 변경하시겠습니까?",
    password_edit: "비밀번호 변경",
    now_password: "현재 비밀번호",
    new_password: "새 비밀번호",
    new_password_confirm: "새 비밀번호 확인",
    now_password_placeholder: "현재 비밀번호를 입력해 주세요.",
    new_password_placeholder: "새 비밀번호 입력를 입력해 주세요.",
    now_password_confirmplaceholder: "새 비밀번호를 한번 더 입력해 주세요.",
    validation_message: "9~32자의 영문 대/소문자, 숫자, 특수문자 포함",
    password_mismatch_message: "비밀번호가 일치하지 않습니다.",
  },
  apikey: {
    api_key: "API KEY 정보",
    scan_count: "스캔 가능 횟수",
    get_count: "조회 가능 횟수",
    time: "회 / 분당",
    unlimited: "무제한",
  },
};

export default mypage;
