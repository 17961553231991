const mypage = {
  profile: {
    profile: "プロフィール設定",
    user_info: "ユーザー情報",
    email: "メールアドレス",
    nickname: "ニックネーム",
    nickname_add: "ニックネームを設定してください。",
    password: "パスワード",
    nickname_edit_confrim_title: "ニックネーム変更確認",
    nickname_edit_confrim_content: "ニックネームを変更してもよろしいですか？",
    password_edit: "パスワード変更",
    now_password: "現在のパスワード",
    new_password: "新しいパスワード",
    new_password_confirm: "新しいパスワードの確認",
    now_password_placeholder: "現在のパスワードを入力してください。",
    new_password_placeholder: "新しいパスワードを入力してください。",
    now_password_confirmplaceholder:
      "新しいパスワードをもう一度入力してください。",
    validation_message:
      "9〜32文字で、大文字、小文字、数字、特殊文字を含む必要があります。",
    password_mismatch_message: "パスワードが一致しません。",
  },
  apikey: {
    api_key: "API KEY情報",
    scan_count: "スキャン可能回数",
    get_count: "確認可能回数",
    time: "回 / 分",
    unlimited: "無制限.",
  },
};

export default mypage;
