<template>
  <router-view />
</template>

<style>
@charset "utf-8";

/* * {
  margin: 0;
  padding: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

html,
body {
  width: 100%;
  height: 100%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans KR", "맑은 고딕", "Malgun Gothic", 돋움, Dotum, Arial,
    "Apple Gothic", sans-serif;
  background: #fcfcfc;
}

a {
  text-decoration: none;
}

img {
  border: 0;
}

li {
  list-style: none;
}

.sticky-wrapper {
  flex-shrink: 0;
}

.menu_bg {
  border-bottom: none !important;
  box-shadow: none !important;
  width: 100%;
  margin-bottom: 15px;
}

.menu_wrap {
  margin: 0 auto;
  width: auto;
  height: 65px;
  overflow: inherit !important;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.menu_inquiry_box {
  width: 100%;
  height: 65px;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;

  background: #ffffff;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  border-radius: 50px;
}

.menu_inquiry_box > a {
  display: block;
}

.menu_logo {
  width: 100px;
  height: 19px;
  background: url(@/assets/img/main_logo_nurilab.svg) no-repeat center center !important;
  background-size: 100px 19px !important;
}

.is-sticky > .menu_bg {
  background: none !important;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .menu_inquiry_box {
    padding: 0 20px 0 20px;
  }
}
</style>
