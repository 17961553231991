const login = {
  login: "로그인",
  save_id: "아이디 저장",
  find_password: "비밀번호 찾기",
  join: "회원가입",
  register_account: "계정 등록",
  agree_terms: "약관 동의",
  agree_to_all: "전체 동의하기",
  nurilab_terms: "[필수] 누리랩 이용약관",
  askurl_terms: "[필수] AskURL 서비스 이용약관",
  personal_terms: "[필수] 개인정보 수집 및 이용 동의",
  purpose_collection_and_use: "개인정보 수집 및 이용 목적",
  purpose_collection_and_use_description:
    "회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.",
  purpose_collection_and_use_item1: "AskURL 회원가입, 서비스 이용",
  what_do_we_collect: "수집하는 개인정보 항목",
  what_do_we_collect_item1: "닉네임, 이메일",
  what_do_we_collect_item2: "수집방법 : 온라인 입력 양식",
  retention: "개인정보 보유 및 이용기간",
  retention_item1: "회원 탈퇴 시 또는 계약 종료 시까지",
  retention_item2: "내부 정책에 따라 보관이 필요한 경우, 고지된 기간까지 보유",
  retention_item3:
    "관련 법령에 따라 보관이 필요한 경우, 해당 법령이 요구하는 기간까지 보유",
  signup: "회원가입",
  required_info: "필수 정보 입력",
  enter_info: "가입을 위해 필수 정보를 입력해 주세요.",
  email: "이메일",
  enter_email: "이메일을 입력해 주세요.",
  name: "닉네임",
  enter_name: "닉네임을 입력해 주세요.",
  password: "비밀번호",
  confirm_password: "비밀번호 확인",
  password_precautions_title: "비밀번호 입력시 주의 사항",
  password_precautions_limit: "9자 이상 32자 이하",
  password_precautions_letter: "영문 대/소문자, 숫자",
  password_precautions_special: "특수문자 {characters} 1개 이상",
  sign_up_completed: "가입 완료",
  enter_password: "비밀번호를 입력해 주세요.",
  enter_confirm: "비밀번호를 한 번 더 입력해 주세요.",
  to_login: "로그인으로 이동",
  retry: "재시도",
  account_in_progress: "계정 활성화 진행중...",
  account_act_complete: "계정 활성화 완료",
  account_activated:
    "계정이 활성화되었습니다. 아래의 ID로 로그인하실 수 있습니다.",
  account_act_fail: "계정 활성화 실패",
  resend_activation: "계정 활성화 요청",
  resend_activation_desc: "계정 활성화를 진행할 이메일을 입력해주세요.",
  internal_error:
    "내부 오류가 발생하였습니다. 잠시 후 다시 시도해주시기 바랍니다.",
  id_not_exist: "등록 ID가 존재하지 않습니다. 관리자에게 문의하시기 바랍니다.",
  instructions_step_1: "이 작업을 수행하려면 메일 인증이 필요합니다.",
  instructions_step_2: "메일 주소를 입력해 주세요.",
  instructions_step_3: "받은 편지함을 확인하시고 제공된 지시에 따라 주세요.",
  reset_password: "비밀번호 재설정",
  new_password: "새 비밀번호",
  confirm_new_password: "새 비밀번호 확인",
  enter_new_password: "새로운 비밀번호를 입력해 주세요.",
  old_password: "기존 비밀번호",
  unlock_account: "계정 잠금 해제",
  unlock_desc: "잠금 해제를 진행할 이메일을 입력해주세요.",
  not_lock: "잠금된 이메일이 아닙니다.",
  unlock_success:
    "계정 잠금이 해제되었습니다. 아래의 ID로 로그인하실 수 있습니다.",
  unlock_fail: "계정 잠금 해제 실패",
};

export default login;
