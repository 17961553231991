const mypage = {
  profile: {
    profile: "Profile Settings",
    user_info: "User Information",
    email: "Email",
    nickname: "Nickname",
    nickname_add: "Please set a nickname.",
    password: "Password",
    nickname_edit_confrim_title: "Confirm Nickname Change",
    nickname_edit_confrim_content: "Do you want to change your nickname?",
    password_edit: "Change Password",
    now_password: "Current Password",
    new_password: "New Password",
    new_password_confirm: "Confirm New Password",
    now_password_placeholder: "Please enter your current password.",
    new_password_placeholder: "Please enter your new password.",
    now_password_confirmplaceholder: "Please re-enter your new password.",
    validation_message:
      "Must include 9~32 characters with uppercase, lowercase letters, numbers, and special characters.",
    password_mismatch_message: "Passwords do not match.",
  },
  apikey: {
    api_key: "API Key Informaiton",
    scan_count: "Scan Limit",
    get_count: "Lookup Limit",
    time: "Times / Minute",
    unlimited: "Unlimited",
  },
};

export default mypage;
