const resourcesRoutes = [
  {
    path: "resources/:id",
    name: "resources",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "@/views/resources/ResourcesView.vue"
      ),
  },
];

export default resourcesRoutes;
