import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { usePreferredLanguages } from "@vueuse/core";

export const useMainStore = defineStore("main", () => {
  const searchText = ref("");
  const classification = ref("");

  const lang = ref<string | null>(localStorage.getItem("lang"));

  const darkMode = ref(localStorage.getItem("dark") == "true");
  const isDarkMode = computed(() => {
    return darkMode.value;
  });
  const toggleDarkMode = () => {
    darkMode.value = !darkMode.value;
    localStorage.setItem("dark", darkMode.value.toString());
  };

  // 지원하는 유효한 언어인지 확인한다
  const isValidLanguage = (language: string) => {
    return language === "ko-KR" || language === "en-US" || language === "ja-JP";
  };

  // 현재 설정되어 있는 언어를 구한다
  // 설정된 언어가 없는 경우 웹브라우저의 기본언어 정보를 이용해서 언어를 설정한후 설정된 언어를 리턴한다
  const language = computed((): string => {
    if (lang.value && isValidLanguage(lang.value)) {
      return lang.value;
    }

    const preferredLang = usePreferredLanguages();
    if (preferredLang.value[0] === "ko" || preferredLang.value[0] === "ko-KR") {
      setLanguage("ko-KR");
    } else if (
      preferredLang.value[0] === "ja" ||
      preferredLang.value[0] === "ja-JP"
    ) {
      setLanguage("ja-JP");
    } else if (
      preferredLang.value[0] === "en" ||
      preferredLang.value[0] === "en-US"
    ) {
      setLanguage("en-US");
    } else {
      setLanguage("en-US");
    }
    if (lang.value) return lang.value;
    else return "en-US";
  });

  const setLanguage = (language: string) => {
    if (isValidLanguage(language)) {
      lang.value = language;
    } else {
      lang.value = "en-US";
    }
    localStorage.setItem("lang", lang.value);
  };

  // 검색 텍스트를 설정한다
  const setSearchText = (text: string) => {
    searchText.value = text;
  };

  const setClassification = (classificationName: string) => {
    classification.value = classificationName;
  };

  const email = ref<string | null>(localStorage.getItem("email"));
  if (!email.value) {
    email.value = "";
  }

  const userEmail = computed(() => {
    return email.value;
  });

  const setUserEmail = (userEmail: string) => {
    email.value = userEmail;
    localStorage.setItem("email", email.value);
  };

  return {
    language,
    setLanguage,
    isDarkMode,
    toggleDarkMode,
    searchText,
    setSearchText,
    classification,
    setClassification,
    userEmail,
    setUserEmail,
  };
});
