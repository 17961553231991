const footer = {
  term_of_use: "이용약관",
  privacy_policy: "개인정보처리방침",
  business_number: "사업자번호",
  address:
    "(07228) 서울특별시 영등포구 영신로 220, 1305호, 1306호(영등포동8가, KnK디지털타워) (주)누리랩",
  ceo: "대표이사",
  ceo_name: "최원혁, 박정호",
  main_phone: "대표전화",
  technical_support: "기술지원",
  fax: "팩스",
  email: "이메일",
};

export default footer;
