import loginRoutes from "./loginRoutes";
import urlsRoutes from "./urlsRoutes";
import resourcesRoutes from "./resourcesRoutes";
import scanningRoutes from "./scanningRoutes";
import newsRoutes from "./newsRoutes";
import mypageRoutes from "./mypageRoutes";

const homeRoutes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunckName: "home" */ "@/views/HomeView.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunckName: "dashboard" */ "@/views/dashboard/DashboardView.vue"
          ),
      },
      ...newsRoutes,
      ...loginRoutes,
      ...scanningRoutes,
      ...urlsRoutes,
      ...resourcesRoutes,
      ...mypageRoutes,
    ],
  },
];

export default homeRoutes;
