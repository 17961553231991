const inquiry = {
  report: {
    report: "報告",
    title: "フィッシングサイト報告",
    explanation:
      "安全なWeb環境を作るために協力していただきありがとうございます。他のWebページを模倣したフィッシングサイトを見つけたら、次のフォームに記入してAskURLセキュリティチームに送信していただきありがとうございます。これにより、より良いセキュリティ対策を講じ、安全なWeb環境に努めます。",
    url: "URLアドレス",
    enter_url: "URLアドレスを入力してください。",
    type: "報告の種類",
    false_positive: "誤検知",
    false_negative: "未検知",
    information: "フィッシングサイトに関する簡単な情報（オプション）",
    enter_information:
      "フィッシングサイトに関する簡単な情報を入力してください。 （オプション）",
    personal_information: "個人情報収集の同意",
    personal_information_use: "個人情報の収集、利用目的",
    personal_information_use_for:
      "当社は、収集した情報を以下の目的に活用します。",
    personal_information_use_by: "ユーザーが報告したフィッシングサイトの分析",
    personal_information_items: "収集する個人情報の項目",
    personal_information_about:
      "URLアドレス、フィッシングサイトに関する簡単な情報",
    personal_information_method: "収集方法：オンライン入力フォーム",
    personal_information_period: "個人情報の保有及び利用期間",
    personal_information_until:
      "個人情報の収集及び利用目的が達成された後、例外なく該当情報を全て破棄します。",
    personal_information_request:
      "顧客の要求に応じて、その情報を即時破棄します。",
    agree_underline: "個人情報の収集、利用目的",
    agree: "に同意します。 (必須)",
    product_askurl: "AskURL",
    product_mars: "MARS",
  },
  contact: {
    contact: "お問い合わせ",
    title: "製品お問い合わせ",
    company_name: "会社名/個人",
    enter_company_name: "会社名を入力してください。",
    department: "部署名",
    enter_department: "部署名を入力してください。",
    product_name: "製品名",
    phone_number: "携帯番号",
    only_number: "数字のみ入力してください。",
    inquiry_details: "お問い合わせ内容",
    enter_inquiry_details: "お問い合わせ内容を入力してください。",
  },
};

export default inquiry;
