const inquiry = {
  report: {
    report: "신고하기",
    title: "피싱 사이트 신고",
    explanation:
      "안전한 웹 환경을 만들기 위해 협력해 주셔서 감사합니다. 만약 다른 웹 페이지를 모방한 피싱 사이트를 발견하게 되면, 아래의 양식을 작성하여 AskURL 보안 팀에 보내주시면 감사하겠습니다. 이를 통해 더 나은 보안 조치를 취하고 안전한 웹 환경을 위해 노력하겠습니다.",
    url: "URL 주소",
    enter_url: "URL 주소를 입력해주세요.",
    type: "신고 유형",
    false_positive: "오탐",
    false_negative: "미탐",
    information: "피싱 사이트에 관한 간략한 정보 (선택사항)",
    enter_information:
      "피싱 사이트에 관한 간략한 정보를 입력해주세요. (선택사항)",
    personal_information: "개인정보 수집동의",
    personal_information_use: "개인정보의 수집, 이용목적",
    personal_information_use_for:
      "회사는 수집한 정보를 다음의 목적을 위해 활용합니다.",
    personal_information_use_by: "사용자가 신고한 피싱 사이트에 대한 분석",
    personal_information_items: "수집하는 개인정보의 항목",
    personal_information_about: "URL 주소, 피싱 사이트에 관한 간략한 정보",
    personal_information_method: "수집방법 : 온라인 입력 양식",
    personal_information_period: "개인정보의 보유 및 이용기간",
    personal_information_until:
      "개인정보 수집 및 이용목적이 달성된 후에 예외 없이 해당정보를 모두 파기합니다.",
    personal_information_request:
      "고객 요청 시, 해당 정보를 즉시 모두 파기합니다.",
    agree_underline: "개인정보의 수집, 이용목적",
    agree: "에 동의합니다. (필수)",
    product_askurl: "AskURL",
    product_mars: "MARS",
  },
  contact: {
    contact: "문의하기",
    title: "제품 문의",
    company_name: "회사명 / 개인",
    enter_company_name: "회사명을 입력해주세요.",
    department: "부서명",
    enter_department: "부서명을 입력해주세요.",
    product_name: "제품명",
    phone_number: "핸드폰번호",
    only_number: "숫자만 입력해주세요.",
    inquiry_details: "문의 내용",
    enter_inquiry_details: "문의내용을 입력해주세요.",
  },
};

export default inquiry;
