const footer = {
  term_of_use: "Terms of Use",
  privacy_policy: "privacy policy",
  business_number: "business_number",
  address:
    "(07228) 1305~1306, 220, Yeongsin-ro, Yeongdeungpo-gu, Seoul, Republic of Korea (KnK Digital Tower)",
  ceo: "CEO",
  ceo_name: "Kei Choi, ChungHo Park",
  main_phone: "Rep",
  technical_support: "Support",
  fax: "Fax",
  email: "Email",
};

export default footer;
