const inquiry = {
  report: {
    report: "Report",
    title: "Report phishing site",
    explanation:
      "Thank you for your cooperation in creating a safe web environment. If you come across a phishing site that mimics another web page, please fill out the form below and send it to the AskURL security team. Through this, we will take better security measures and strive to create a safer web environment.",
    url: "URL address",
    enter_url: "Please enter the URL address.",
    type: "Report Type",
    false_positive: "False positive",
    false_negative: "False negative",
    information: "Brief information about phishing sites (optional)",
    enter_information:
      "Please enter brief information about the phishing site. (options)",
    personal_information: "Consent to collection of personal information",
    personal_information_use:
      "Purpose of collection and use of personal information",
    personal_information_use_for:
      "The company uses the collected information for the following purposes.",
    personal_information_use_by: "Analysis of phishing sites reported by users",
    personal_information_items: "Items of personal information collected",
    personal_information_about:
      "URL address, brief information about phishing site",
    personal_information_method: "Collection method: Online input form",
    personal_information_period:
      "Retention and use period of personal information",
    personal_information_until:
      "After the purpose of collecting and using personal information is achieved, all such information is destroyed without exception.",
    personal_information_request:
      "Upon customer request, all relevant information will be destroyed immediately.",
    agree_underline: "Purpose of collection and use of personal information",
    agree: "I agree. (mandatory)",
    product_askurl: "AskURL",
    product_mars: "MARS",
  },
  contact: {
    contact: "Contact",
    title: "Product Inquiry",
    company_name: "Company name / individual",
    enter_company_name: "Please enter your company name.",
    department: "Department",
    enter_department: "Please enter the department name.",
    product_name: "Product name",
    phone_number: "Phone Number",
    only_number: "Please enter only numbers.",
    inquiry_details: "Inquiry details",
    enter_inquiry_details: "Please enter your inquiry details.",
  },
};

export default inquiry;
