import { computed, ref } from "vue";
import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { AuthToken, AccessToken, RefreshToken } from "@/types/user";
import { authApi } from "@/api/authApi";
import router from "@/router";

const loadAccessJwt = (): string => {
  const sValue: string | null = sessionStorage.getItem("access");
  if (sValue === null) {
    return "";
  }
  return sValue;
};

const loadRefreshJwt = (): string => {
  const sValue: string | null = sessionStorage.getItem("refresh");
  if (sValue === null) {
    return "";
  }
  return sValue;
};

const getTimeZone = (): string => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!tz) {
    tz = "Asia/Seoul";
  }
  return tz;
};

const loadDateTimeFormat = (): string => {
  const sValue: string | null = sessionStorage.getItem("datetime_format");
  if (sValue === null) {
    return "yyyy-MM-dd";
  }
  return sValue;
};

export const useAuthStore = defineStore("auth", () => {
  const accessJwt = ref(loadAccessJwt());
  const refreshJwt = ref(loadRefreshJwt());
  const time_zone = ref(getTimeZone());
  const datetime_format = ref(loadDateTimeFormat());

  const saveAccessJwt = (sValue: string) => {
    accessJwt.value = sValue;
    sessionStorage.setItem("access", sValue);
  };

  const saveRefreshJwt = (sValue: string) => {
    refreshJwt.value = sValue;
    sessionStorage.setItem("refresh", sValue);
  };

  //로그인 토큰 정보 저장
  const setAuthToken = (at: AuthToken) => {
    saveAccessJwt(at.access_jwt);
    saveRefreshJwt(at.refresh_jwt);
  };

  //로그인 토큰 정보 삭제
  const clearAuthToken = async () => {
    saveAccessJwt("");
    saveRefreshJwt("");
  };

  //Refresh 토큰을 이용하여 Access 토큰 정보를 갱신
  const refreshAuthToken = async (): Promise<string | null> => {
    const [status, data] = await authApi.Refresh(
      accessJwt.value,
      refreshJwt.value
    );
    if (status === 200) {
      setAuthToken(data as AuthToken);
    } else {
      clearAuthToken();
      await requestLogout();
      router?.push({ name: "login" });
    }
    return accessJwt.value;
  };

  const requestLogout = async (): Promise<void> => {
    await authApi.Logout(refresh_token_id.value);
  };

  const isAgreeTerms = (accessJwt: string) => {
    const aToken = jwt_decode<AccessToken>(accessJwt);
    if (!aToken) {
      return false;
    }
    return aToken.terms_status;
  };

  const getEmail = (accessJwt: string) => {
    const aToken = jwt_decode<AccessToken>(accessJwt);
    if (!aToken) {
      return "";
    }
    return aToken.email;
  };

  const getPlatform = (accessJwt: string) => {
    const aToken = jwt_decode<AccessToken>(accessJwt);
    if (!aToken) {
      return "";
    }
    return aToken.platform;
  };

  const accessToken = computed((): AccessToken => {
    if (accessJwt.value) {
      return jwt_decode<AccessToken>(accessJwt.value);
    }
    return {} as AccessToken;
  });

  const refreshToken = computed((): RefreshToken => {
    if (refreshJwt.value) {
      return jwt_decode<RefreshToken>(refreshJwt.value);
    }
    return {} as RefreshToken;
  });

  const access_id = computed((): string => {
    return accessToken.value.access_id;
  });

  const email = computed(() => {
    return accessToken.value.email;
  });

  const platform = computed(() => {
    return accessToken.value.platform;
  });

  const name = computed(() => {
    return accessToken.value.name;
  });

  const terms_status = computed(() => {
    return accessToken.value.terms_status;
  });

  const refresh_token_id = computed(() => {
    return refreshToken.value.rid;
  });

  const datetime_format_for_table = computed(() => {
    const format = datetime_format.value || "yyyy-MM-dd";
    return format.toUpperCase() + " HH:mm:ss";
  });

  const datetime_format_year = computed(() => {
    const format = datetime_format.value || "yyyy-MM-dd";
    return format.toUpperCase();
  });

  return {
    accessJwt,
    accessToken,
    refreshJwt,
    access_id,
    email,
    platform,
    name,
    terms_status,
    time_zone,
    datetime_format,
    datetime_format_for_table,
    setAuthToken,
    clearAuthToken,
    refreshAuthToken,
    isAgreeTerms,
    getEmail,
    getPlatform,
    datetime_format_year,
    requestLogout,
  };
});
