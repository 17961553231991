const urlsRoutes = [
  {
    path: "urls/:id",
    name: "urls",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "urls/:id/resources",
    name: "urls-resources",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
  {
    path: "urls/:id/links",
    name: "urls-links",
    component: () =>
      import(
        /* webpackChunkName: "summary" */ "@/views/summary/SummaryView.vue"
      ),
  },
];

export default urlsRoutes;
