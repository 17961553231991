import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "@/store/auth";

const mypageRoutes = [
  {
    path: "mypage",
    name: "mypage",
    component: () =>
      import(/* webpackChunkName: "mypage" */ "@/views/mypage/MypageView.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();
      if (!authStore.accessJwt) {
        next({ name: "login" });
      } else {
        next();
      }
    },
  },
];

export default mypageRoutes;
