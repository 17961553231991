import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { useAuthStore } from "@/store/auth";

const loginRoutes = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/LoginView.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const authStore = useAuthStore();
      if (authStore.accessJwt) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "@/views/login/SignupView.vue"),
  },
  {
    path: "/callback",
    name: "callback",
    component: () =>
      import(
        /* webpackChunkName: "callback" */ "@/views/dashboard/CallbackView.vue"
      ),
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () =>
      import(
        /* webpackChunckName: "forgot" */ "@/views/login/ForgotPassword.vue"
      ),
  },
  {
    path: "/activation/:id",
    name: "activation",
    component: () =>
      import(
        /* webpackChunkName: "activation" */ "@/views/login/ActivationView.vue"
      ),
  },
  {
    path: "/activation/resend",
    name: "resend-activation-mail",
    component: () =>
      import(
        /* webpackChunkName: "resend-activation-mail" */ "@/views/login/ResendActivationMailView.vue"
      ),
  },
  {
    path: "/password/reset/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunckName: "reset-password" */ "@/views/login/ResetPasswordView.vue"
      ),
  },
  {
    path: "/lock",
    name: "send-lock-mail",
    component: () =>
      import(
        /* webpackChunckName: "send-lock-mail" */ "@/views/login/SendLockMailView.vue"
      ),
  },
  {
    path: "/unlock/:id",
    name: "unlock",
    component: () =>
      import(
        /* webpackChunckName: "unlock" */ "@/views/login/UnlockAccount.vue"
      ),
  },
];

export default loginRoutes;
