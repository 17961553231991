const login = {
  login: "Login",
  save_id: "Save id",
  find_password: "Find password",
  join: "Sign Up",
  register_account: "Account registration",
  agree_terms: "Agree Terms",
  agree_to_all: "Agree to all",
  nurilab_terms: "[required] Nurilab Terms of Use",
  askurl_terms: "[required] AskURL Terms of Use",
  personal_terms: "[required] Agree Personal Information Collection and Use",
  purpose_collection_and_use: "Purpose of Collection and Use",
  purpose_collection_and_use_description:
    "We use the personal information we collect for the following purposes.",
  purpose_collection_and_use_item1: "Sign up for AskURL, use our services",
  what_do_we_collect: "What personal informationdo we collect",
  what_do_we_collect_item1: "Nickname, email",
  what_do_we_collect_item2: "Collection method : online input form",
  retention: "Retention and use period of personal information",
  retention_item1: "Until you cancel your membership or end of contract.",
  retention_item2:
    "If retention is required by internal policy, retain for a notified period of time.",
  retention_item3:
    "If retention is required by applicable law, for as long as required by applicable law.",
  signup: "Sign up",
  required_info: "Enter required information",
  enter_info: "Please enter the required information to sign up",
  email: "Email",
  enter_email: "Please enter your email",
  name: "Nickname",
  enter_name: "Please enter your nickname",
  password: "Password",
  enter_password: "Please enter your password",
  confirm_password: "Confirm Password",
  password_precautions_title: "Precautions when entering password",
  password_precautions_limit: "9 to 32 characters",
  password_precautions_letter: "English upper/lower case letters, numbers",
  password_precautions_special: "At least 1 special character !@#$%^&*()",
  sign_up_completed: "Sign up completed",
  enter_confirm: "Please enter your password again.",
  to_login: "Go to Login",
  retry: "Retry",
  account_in_progress: "Account activation in progress...",
  account_act_complete: "Account activation complete",
  account_activated:
    "Your account has been activated. You can log in with the ID below.",
  account_act_fail: "Account activation failed",
  resend_activation: "Account activation request.",
  resend_activation_desc: "Enter your email to activate your account.",
  internal_error: "An internal error has occurred. Please try again later.",
  id_not_exist:
    "Registration ID does not exist. Please contact your administrator.",
  instructions_step_1: "This operation requires email authentication. ",
  instructions_step_2: "Please enter your email address. ",
  instructions_step_3:
    "Please check your inbox and follow the instructions provided.",
  reset_password: "Reset password",
  new_password: "New password.",
  confirm_new_password: "New password confirmation",
  enter_new_password: "Please enter a new password.",
  old_password: "Old password",
  unlock_account: "Unlock account",
  unlock_desc: "Enter email address to unlock.",
  not_lock: "This Email is not locked",
  unlock_success:
    "Account has been unlocked. You can log in with the ID below.",
  unlock_fail: "Account unlock failed",
};

export default login;
