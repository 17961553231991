const login = {
  login: "ログイン",
  save_id: "IDを保存",
  find_password: "パスワードを探す",
  join: "会員登録",
  register_account: "アカウント登録",
  agree_terms: "規約に同意",
  agree_to_all: "すべてに同意",
  nurilab_terms: "[必須】ヌリラボ利用規約",
  askurl_terms: "[必須] AskURLサービス利用規約",
  personal_terms: "[必須] 個人情報収集・利用同意",
  purpose_collection_and_use: "[必須] 個人情報収集・利用同意",
  purpose_collection_and_use_description:
    "[必須]個人情報の収集及び利用 同協議会は、収集した個人情報を次の目的のために活用します。",
  purpose_collection_and_use_item1: "AskURL会員登録、サービス利用",
  what_do_we_collect: "収集する個人情報項目",
  what_do_we_collect_item1: "ニックネーム、メールアドレス",
  what_do_we_collect_item2: "収集方法：オンライン入力フォーム",
  retention: "個人情報の保有及び利用期間",
  retention_item1: "退会時または契約終了時まで",
  retention_item2: "内部方針により保管が必要な場合、告知された期間まで保管。",
  retention_item3:
    "関連法令に基づき保管が必要な場合、当該法令が要求する期間まで保有します。",
  signup: "会員登録",
  required_info: "必須情報の入力",
  enter_info: "登録に必要な情報を入力してください。",
  email: "メール",
  enter_email: "メールを入力してください。",
  name: "ニックネーム",
  enter_name: "ニックネームを入力してください。",
  password: "パスワード",
  enter_password: "パスワードを入力してください。",
  confirm_password: "パスワード確認",
  password_precautions_title: "パスワード入力の注意事項",
  password_precautions_limit: "9字以上32字以下",
  password_precautions_letter: "英語の大/小文字、数字",
  password_precautions_special: "特殊文字 !@#$%^&*() 1つ以上",
  sign_up_completed: "登録完了",
  enter_confirm: "パスワードをもう一度入力してください。",
  to_login: "ログインに移動",
  retry: "再試行",
  account_in_progress: "アカウントの有効化中...",
  account_act_complete: "アカウントの有効化",
  account_activated:
    "アカウントが有効になっています。下記のIDでログインできます。",
  account_act_fail: "アカウントの有効化失敗",
  resend_activation: "アカウントのアクティベーション依頼",
  resend_activation_desc:
    "アカウントのアクティベーションを行うメールアドレスを入力してください。",
  internal_error:
    "内部エラーが発生しました。しばらくしてからもう一度お試しください。",
  id_not_exist: "登録IDが存在しません。管理者にお問い合わせください。",
  instructions_step_1: "これを行うにはメール認証が必要です。",
  instructions_step_2: "メールアドレスを入力してください。",
  instructions_step_3: "受信トレイを確認し、提供された指示に従ってください。",
  reset_password: "パスワードリセット",
  new_password: "新しいパスワード",
  confirm_new_password: "新しいパスワードを確認",
  enter_new_password: "新しいパスワードを入力してください。",
  old_password: "既存のパスワード",
  unlock_account: "アカウントロック解除",
  unlock_desc: "ロックを解除するメールを入力してください。",
  not_lock: "ロックされたメールではありません。",
  unlock_success:
    "アカウントのロックが解除されました。下記の ID でログインできます。",
  unlock_fail: "アカウントロック解除失敗",
};

export default login;
